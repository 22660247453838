<template>
  <v-card elevation="0" class="title">
    <v-tabs
      height="35"
      @change="changeTab($event)"
      slider-size="3"
      hide-slider=""
      centered=""
      v-model="currentItem"
    >
      <v-tab v-for="item in items" :key="item" :href="'#tab-' + item">
        {{ item }}</v-tab
      >
    </v-tabs>
    <v-tabs-items v-model="currentItem" style="padding: 10 10px 10px 0px;">
      <v-tab-item v-for="item in items" :key="item" :value="'tab-' + item">
        <v-card flat tile elevation="0">
          <s-crud
            v-if="item == 'Usuarios'"
            height="150"
            :config="ConfigTrigger"
            :title="item"
            @save="saveTrigger($event)"
            :filter="filterTrigger"
            add
            remove
          >
            <template scope="props">
              <v-col>
                <v-row justify="center">
                  <v-col class="s-col-form" lg="4" md="4" sm="6" xs="12">
                    <s-select-position
                      label="Cargo"
                      v-model="props.item.PstID"
                    ></s-select-position>
                  </v-col>
                </v-row>
              </v-col>
            </template>
          </s-crud>
          <s-crud
            v-if="item == 'Niveles'"
            :filter="filterLevels"
            add
            remove
            height="120"
            :config="configLevels"
            :title="item"
            @save="saveLevel($event)"
          >
            <template v-if="item == 'Niveles'" v-slot:options></template>
            <template scope="props">
              <v-col>
                <v-row justify="center">
                  <v-col class="s-col-form" lg="4" md="4" sm="6" xs="12">
                    <s-select-position
                      label="Cargo"
                      v-model="props.item.PstID"
                    ></s-select-position>
                  </v-col>
                  <v-col lg="3" class="s-col-form">
                    <s-select-definition
                      add
                      :def="1137"
                      v-model="props.item.TypeLevel"
                      label="Tipo de Nivel"
                    ></s-select-definition>
                  </v-col>
                </v-row>
              </v-col>
            </template>
          </s-crud>
          <s-crud
            v-if="item == 'Condiciones'"
            :filter="filterCondition"
            add            
            edit
            remove
            height="120"
            :config="ConfigCondition"
            :title="item"
            @save="saveCondition($event)"
          >
            <template v-if="item == 'Condiciones'" v-slot:options></template>
            <template scope="props">
              <v-col>
                <v-row justify="center">
                  <v-col class="s-col-form" lg="3" md="3" sm="4" xs="12">
                    <s-select-definition
                      add
                      :def="1140"
                      v-model="props.item.TypeTerms"
                      label="Tipo de Condición"
                    ></s-select-definition>
                  </v-col>
                  <v-col
                    v-show="props.item.TypeTerms == 1"
                    class="s-col-form"
                    lg="2"
                    md="2"
                    sm="3"
                    xs="12"
                  >
                    <s-select-definition
                      add
                      :def="1148"
                      v-model="props.item.TypeOperator"
                      label="Operador Lógico"
                    ></s-select-definition>
                  </v-col>
                  <v-col
                    v-show="props.item.TypeTerms === 1"
                    class="s-col-form"
                    lg="2"
                    md="2"
                    sm="3"
                    xs="12"
                  >
                    <s-select-property-settings
                      v-model="props.item.Condition"
                      full return-object
                      clearable
                      :table=circuit.TrsTableName
                      label="Campo de Condición"
                      :text="props.item.AtmCondition"
                    ></s-select-property-settings>
                  </v-col>
                  <v-col
                    v-show="props.item.TypeTerms == 2"
                    class="s-col-form"
                    lg="4"
                    md="4"
                    sm="4"
                    xs="12"
                  >
                    <s-select-definition
                      add
                      :def="1149"
                      returnObject
                      v-model="props.item.Condition"
                      label="Procedimientos"
                      full
                      clearable
                    ></s-select-definition>
                  </v-col>
                  <v-col
                    v-show="props.item.TypeTerms == 1"
                    class="s-col-form"
                    lg="2"
                    md="2"
                    sm="2"
                    xs="4"
                    ><s-text
                      v-model="props.item.AtmValue"
                      label="Valor de Condición"
                      autofocus
                    />
                  </v-col>
                </v-row>
              </v-col>
            </template>
          </s-crud>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
import _sApprovalCircuit from "@/services/Security/ApprovalCircuitService";
import _sApprovalTrigger from "@/services/Security/ApprovalTriggerService";
import _sApprovalLevel from "@/services/Security/ApprovalLevelsService";
import _sApprovalTerms from "@/services/Security/ApprovalTermsService";
export default {
  components: {},
  props: {
    parentID: { type: Number, default: 0 },
    circuit: { type: Object, default: null },
  },
  data() {
    return {
      tabSelected: "",
      currentItem: "tab-Usuarios",
      items: ["Usuarios", "Niveles", "Condiciones"],

      filterTrigger: {},
      filterLevels: {},
      filterCondition: {},
      configLevels: {
        model: {
          AccID: "int",
          AlvID: "ID",
          SecStatus: "boleam",
          PstID: "int",
          PstName: "string",
        },
        service: _sApprovalLevel,
        headers: [
          { text: "ID", value: "AlvID" },
          { text: "Cargo", value: "PstName" },
          { text: "Nivel", value: "TypeLevelText" },
        ],
      },

      ConfigTrigger: {
        model: {
          AccID: "int",
          AtgID: "ID",
          SecStatus: "boleam",
          PstID: "int",
          PstName: "string",
        },
        service: _sApprovalTrigger,
        headers: [
          { text: "ID", value: "AtgID" },
          { text: "Cargo", value: "PstName" },
        ],
      },
      ConfigCondition: {
        model: {
          AccID: "int",
          AtmID: "ID",
          SecStatus: "boleam",
          TypeTermsText: "string",
          AtmCondition: "string",
          TypeOperatorText: "string",
          TypeOperator: "int",
          Condition: [],
        },
        service: _sApprovalTerms,
        headers: [
          { text: "ID", value: "AtmID", width: "80" },
          { text: "Tipo", value: "TypeTermsText", width: "150" },
          { text: "Operador", value: "TypeOperatorText", width: "200" },
          { text: "Condicion", value: "AtmCondition" },
          { text: "Valor", value: "AtmValue", width: "150" },
        ],
      },
      selected: "",
    };
  },
  computed: {
    config() {
      return {
        model: {
          UsrName: "string",
          UsrID: "ID",
          NtpID: "int",
          NtpFullName: "string",
          PrsDocumentNumber: "string",
          SecStatus: "status",
          UsrStatus: "boolean",
          UsrPassword: "string",
          UsrPasswordChange: "boolean",
        },
        service: _sUser,
        headers: this.isCrud
          ? [
              {
                text: "ID",
                value: "UsrID",
                align: "end",
              },
              {
                text: "Usuario",
                value: "UsrName",
              },
              { text: "Persona", value: "NtpFullName" },
              {
                width: "5%",
                text: "Est",
                value: "UsrStatus",
                sortable: false,
              },
              {
                width: "5%",
                text: "Sec",
                value: "SecStatus",
                sortable: false,
              },
            ]
          : [
              {
                text: "Usuario",
                value: "UsrName",
              },
              { text: "Persona", value: "NtpFullName" },
            ],
      };
    },
  },
  watch: {
    
    circuit(){

  },
    parentID() {
      this.tabSelectedData();
    },
  },
  created() {
    this.tabSelected = this.currentItem;
    this.filterTrigger = { AccID: this.parentID };
  },
  methods: {
    rowSelected(items) {
      if (items.length > 0) this.userSelected = this.isCrud ? items[0] : items;
      else this.userSelected = this.isCrud ? {} : [];
    },
    tabSelectedData() {
      if (this.tabSelected == "tab-Usuarios")
        this.filterTrigger = { AccID: this.parentID };
      else if (this.tabSelected == "tab-Niveles")
        this.filterLevels = { AccID: this.parentID };
      else if (this.tabSelected == "tab-Condiciones") {
        this.filterCondition = { AccID: this.parentID };
      }
    },
    changeTab(n) {
      this.tabSelected = n;
      this.tabSelectedData();
    },
    ValidateSelect(item) {
      console.log("", item);
    },
    saveTrigger(item) {
      item.AccID = this.parentID;
      if (item.PstID == 0) {
        this.$fun.alert("Seleccione Cargo", "warning");
        return;
      }
      item.save();
    },
    saveLevel(item) {
      item.AccID = this.parentID;
      if (item.PstID == 0) {
        this.$fun.alert("Seleccione Cargo", "warning");
        return;
      } else {
        if (item.TypeLevel == 0) {
          this.$fun.alert("Seleccione Nivel", "warning");
          return;
        }
      }
      item.save();
    },

    isValidate(item) {
      let isValid;
      let message;

      isValid = item.TypeTerms > 0;
      if (!isValid) message = "Seleccione tipo de Condicion";
      else {
        if (item.TypeTerms === 1) {
          isValid = item.TypeOperator > 0;
          if (!isValid) message = "Seleccione Operador";
          isValid = item.Condition != null;
          if (!isValid) message = "Seleccione Campo de Codicion";
          else {
            isValid = item.AtmValue != null;
            if (!isValid) message = "Ingrese valor de condicion";
          }
        } else {
          isValid = item.Condition != null;
          if (!isValid) message = "Seleccione el procedimiento";
        }
      }

      if (!isValid) this.$fun.alert(message, "warning");

      return isValid;
    },

    saveCondition(item) {
      item.AccID = this.parentID;
      item.TypeOperator = item.TypeTerms == 1 ? item.TypeOperator : null;
      item.AtmCondition =
        item.TypeTerms == 1
          ? item.Condition.GenHelper
          : item.Condition.DedAbbreviation;
      item.SecStatus = item.SecStatus;

      if (this.isValidate(item)) item.save();
    },
  },
};
</script>
