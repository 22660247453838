<template>
  <v-row>
      <v-col lg="6"  cols=12 style="padding-bottom:0px;">
        <s-crud
          title="Circuitos de Aprobacion"
          :config="config"
          :add="isCrud"
          :edit="isCrud"
          ref="gridAcc"
          :remove="isCrud"
          :filter="filter"
          @save="saveAcc($event)"  
          @rowSelected="rowSelected"
          searchInput
          :singleRow="singleRow"
        >
          <template slot="filter">
            <v-container>
            <v-row class="justify-center">
              <v-switch
                  label="Circuitos activos"
                  v-model="filter.SecStatus"
                  dense
                  hide-details
                  :false-value="0"
                  :true-value="1"
                />
            </v-row>
            </v-container>
          </template>
          <template v-if="isCrud" v-slot:default="{ item }">
            <v-container>
              <v-row justify="center">
                <v-col cols="8" class="s-col-form">
                  <s-text
                    v-model="item.AccName"
                    label="Nombre de Circuito"
                    autofocus
                  />
                </v-col>    
                
              </v-row>
              <v-row justify="center">
                 <v-col cols="4" class="s-col-form">
                  <s-select-transaction
                    v-model="item.TypeCircuit"
                    label="Tipo Circuito"
                  />
                </v-col>
                <v-col cols="2" class="s-col-form">
                  <s-date label="Fecha Inicio" v-model="item.AccBeginDate" />
                </v-col>
                <v-col cols="2" class="s-col-form">
                  <s-date clearable label="Fecha Fin" v-model="item.AccEndDate" />
                </v-col>
               

              <!--  <v-col cols="1" class="s-col-form">
                  <s-switch class="mt-2" label="Activo" v-model="item.SecStatus" />
                </v-col>-->
              </v-row>
            </v-container>
          </template>
          <template v-slot:SecStatus="{ row }">
            <v-icon :color="row.SecStatus == 1 ? 'success' : 'default'">
              mdi-checkbox-blank-circle</v-icon
            >
          </template>
        </s-crud>
        <v-divider></v-divider>
      </v-col>
      <v-col lg="6" >
        <s-autorize-detail :circuit=circuit v-if="parentID != 0" :parentID="parentID" />
      </v-col>
  </v-row>
</template>
<script>
import _sApprovalCircuit from "@/services/Security/ApprovalCircuitService";
import sAutorizeDetail from "./AutorizeDetail.vue";
export default {
  components: { sAutorizeDetail },
  props: {
    singleRow: { type: Boolean, default: true },
    isCrud: { type: Boolean, default: true },
  },
  data() {
    return {
      acc: [],
      filter: {
        SecStatus: 1,
      },
      parentID: 0,
    };
  },
  computed: {
    config() {
      return {
        model: {
          circuit:null,
          AccName: "string",
          AccID: "ID",
          AccBeginDate: "date",
          AccEndDate: "date",
          TypeCircuitText: "string",
          AreName: "string",
          SecStatus: "status",
        },
        service: _sApprovalCircuit,
        headers: this.isCrud
          ? [
              {
                text: "ID",
                value: "AccID",
                align: "end",
              },
              { text: "Circuito", value: "AccName" },
              { text: "Tipo Circuito", value: "TypeCircuitText" },
              {
                text: "Fecha Inicio",
                value: "AccBeginDate",
              },
              {
                text: "Fecha Fin",
                value: "AccEndDate",
              },
              {
                width: "5%",
                text: "Sec",
                value: "SecStatus",
                sortable: false,
              },
            ]
          : [
              {
                text: "ID",
                value: "AccID",
                align: "end",
              },
              { text: "Circuito", value: "AccName" },
            ],
      };
    },
  },
  methods: {
    rowSelected(rows) {
      if (rows.length > 0) {
      this.circuit=rows[0];
        this.parentID = rows[0].AccID;
      }
    },

    validatedCircuit(item) {
      let isValid = false;
      let message = "";

      isValid = item.AccName.length > 0;
      if (!isValid) message = "Ingrese Nombre de Circuito";
      else {
        isValid = item.AccBeginDate;
        if (!isValid) message = "Ingrese Fecha de Inicio Circuito";
        else {
        }
      }

      if (!isValid) this.$fun.alert(message, "warning");

      return isValid;
    },
    saveAcc(item) {
      if (this.validatedCircuit(item)) {
        item.save();
      }
    },
  },
};
</script>
