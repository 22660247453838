import Service from "../Service";

const resource = "approvalcircuit/";

export default {
  save(usr, requestID) {
    return Service.post(resource + "save", usr, {
      params: { requestID: requestID },
    });
  },
  pagination(parameters, requestID) {
    return Service.post(resource + "pagination", parameters, {
      params: { requestID: requestID },
    });
  },  
};